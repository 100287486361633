import { DefaultLayout, AuthLayout } from "@/layouts/index";
import ECommerceVue from "@/views/dashboard/ECommerce.vue";

const authPrefix = "/geoblue/auth";
const pagesPrefix = "/pages";

const accountRoutes = [
  {
    path: `/login`,
    name: "AccountSignIn",
    component: () => import("@/views/account/SignIn.vue"),
    meta: { title: "Logar", authRequired: false },
  },
  {
    path: `/signup`,
    name: "AccountSignUp",
    component: () => import("@/views/account/SignUp.vue"),
    meta: { title: "Sign Up", authRequired: false }
  },
  {
    path: `/pass-reset`,
    name: "AccountResetPassword",
    component: () => import("@/views/account/ResetPassword.vue"),
    meta: { title: "Resetar Senha", authRequired: false }
  },
  {
    path: `/pass-change`,
    name: "AccountChangePassword",
    component: () => import("@/views/account/CreatePassword.vue"),
    meta: { title: "Criar nova senha", authRequired: false }
  }
].map((data) => {
  return {
    ...data,
    meta: { ...data.meta, layout: AuthLayout }
  };
});

const dashboardRoutes = [
  {
    path: "/",
    name: "ECommerce",
    component: ECommerceVue,
    meta: { title: "Dashboard", authRequired: true, layout: DefaultLayout }
  }
].map((data) => {
  return {
    ...data,
    meta: { ...data.meta, layout: DefaultLayout }
  };
});

const authRoutes = [
  {
    path: `${authPrefix}/signin`,
    name: "SignIn",
    component: () => import("@/views/authentication/SignIn.vue"),
    meta: { title: "Sign In", authRequired: false }
  },
  {
    path: `${authPrefix}/signup`,
    name: "SignUp",
    component: () => import("@/views/authentication/SignUp.vue"),
    meta: { title: "Sign Up", authRequired: false }
  },
  {
    path: `${authPrefix}/pass-reset`,
    name: "ResetPassword",
    component: () => import("@/views/authentication/ResetPassword.vue"),
    meta: { title: "Reset Password", authRequired: false }
  },
  {
    path: `${authPrefix}/pass-change`,
    name: "ChangePassword",
    component: () => import("@/views/authentication/CreatePassword.vue"),
    meta: { title: "Create New Password", authRequired: false }
  },
  {
    path: `${authPrefix}/lockscreen`,
    name: "LockScreen",
    component: () => import("@/views/authentication/LockScreen.vue"),
    meta: { title: "Lock Screen", authRequired: false }
  },
  {
    path: `${authPrefix}/logout`,
    name: "LogOut",
    component: () => import("@/views/authentication/Logout.vue"),
    meta: { title: "Log Out", authRequired: false }
  },
  {
    path: `${authPrefix}/success-msg`,
    name: "SuccessMessage",
    component: () => import("@/views/authentication/SuccessMsg.vue"),
    meta: { title: "Success Message", authRequired: false }
  },
  {
    path: `${authPrefix}/twostep`,
    name: "TwoStepVerification",
    component: () => import("@/views/authentication/TwoStepVerification.vue"),
    meta: { title: "Two Step Verification", authRequired: false }
  },
  {
    path: `${authPrefix}/404`,
    name: "Error404",
    component: () => import("@/views/authentication/error/404.vue"),
    meta: { title: "404 Error", authRequired: false }
  },
  {
    path: `${authPrefix}/500`,
    name: "Error500",
    component: () => import("@/views/authentication/error/500.vue"),
    meta: { title: "500 Error", authRequired: false }
  },
  {
    path: `${authPrefix}/503`,
    name: "Error503",
    component: () => import("@/views/authentication/error/503.vue"),
    meta: { title: "503 Error", authRequired: false }
  },
  {
    path: `${authPrefix}/offline`,
    name: "Offline",
    component: () => import("@/views/authentication/error/Offline.vue"),
    meta: { title: "Offline Page", authRequired: false }
  }
].map((data) => {
  return {
    ...data,
    meta: { ...data.meta, layout: AuthLayout }
  };
});

const errorRoutes  = [
  {
    path: `/423`,
    name: "Locked",
    component: () => import("@/views/pages/error/423.vue"),
    meta: { title: "Não Autorizado", authRequired: true, layout: DefaultLayout }
  },
  {
    path: `/404`,
    name: "NotFound",
    component: () => import("@/views/pages/error/404.vue"),
    meta: { title: "Não Encontrado", authRequired: true, layout: DefaultLayout }
  },
];


const pagesRoutes = [
  {
    path: `${pagesPrefix}/starter`,
    name: "Starter",
    component: () => import("@/views/pages/StarterKit.vue"),
    meta: { title: "Starter", authRequired: false, layout: DefaultLayout }
  },
  {
    path: `${pagesPrefix}/maintenance`,
    name: "PagesMaintenance",
    component: () => import("@/views/pages/Maintenance.vue"),
    meta: { title: "Maintenance", authRequired: false, layout: AuthLayout }
  },
  {
    path: `${pagesPrefix}/coming-soon`,
    name: "PagesComingSoon",
    component: () => import("@/views/pages/ComingSoon.vue"),
    meta: { title: "Coming Soon", authRequired: false, layout: AuthLayout }
  }
];

const cadastros  = [
  {
    path: `/cadastros/alas`,
    name: "cadastrosAlas",
    component: () => import("@/views/pages/cadastros/alas/index.vue"),
    meta: { title: "Alas", authRequired: false, layout: DefaultLayout }
  },
  {
    path: `/cadastros/alas/cadastrar`,
    name: "cadastrosAlasCadastrar",
    component: () => import("@/views/pages/cadastros/alas/register.vue"),
    meta: { title: "Cadastrar Ala", authRequired: false, layout: DefaultLayout }
  },
  {
    path: `/cadastros/alas/editar/:id`,
    name: "cadastrosAlasEditar",
    props: true,
    component: () => import("@/views/pages/cadastros/alas/register.vue"),
    meta: { title: "Cadastrar Ala", authRequired: false, layout: DefaultLayout }
  },
  {
    path: `/cadastros/cids`,
    name: "cadastrosCids",
    component: () => import("@/views/pages/cadastros/cids/index.vue"),
    meta: { title: "CIDs", authRequired: false, layout: DefaultLayout }
  },
  {
    path: `/cadastros/funcoes`,
    name: "cadastrosFuncoes",
    component: () => import("@/views/pages/cadastros/funcoes/index.vue"),
    meta: { title: "Funções", authRequired: false, layout: DefaultLayout }
  },
  {
    path: `/cadastros/funcoes/cadastrar`,
    name: "cadastrosFuncoesCadastrar",
    component: () => import("@/views/pages/cadastros/funcoes/register.vue"),
    meta: { title: "Cadastrar Funções", authRequired: false, layout: DefaultLayout }
  },
  {
    path: `/cadastros/funcoes/editar/:id`,
    name: "cadastrosFuncoesEditar",
    props: true,
    component: () => import("@/views/pages/cadastros/funcoes/register.vue"),
    meta: { title: "Cadastrar Funções", authRequired: false, layout: DefaultLayout }
  },
  {
    path: `/cadastros/caps`,
    name: "cadastrosCaps",
    component: () => import("@/views/pages/cadastros/caps/index.vue"),
    meta: { title: "Caps", authRequired: false, layout: DefaultLayout }
  },
  {
    path: `/cadastros/caps/cadastrar`,
    name: "cadastrosCapsCadastrar",
    component: () => import("@/views/pages/cadastros/caps/register.vue"),
    meta: { title: "Cadastrar Caps", authRequired: false, layout: DefaultLayout }
  },
  {
    path: `/cadastros/caps/editar/:id`,
    name: "cadastrosCapsEditar",
    props: true,
    component: () => import("@/views/pages/cadastros/caps/register.vue"),
    meta: { title: "Editar APs", authRequired: false, layout: DefaultLayout }
  },
  {
    path: `/cadastros/pessoas`,
    name: "cadastrosPessoas",
    component: () => import("@/views/pages/cadastros/pessoas/index.vue"),
    meta: { title: "Residentes", authRequired: false, layout: DefaultLayout }
  },
  {
    path: `/cadastros/pessoas/cadastrar`,
    name: "cadastrosPessoasCadastrar",
    component: () => import("@/views/pages/cadastros/pessoas/register.vue"),
    meta: { title: "Cadastrar Residente", authRequired: false, layout: DefaultLayout }
  },
  {
    path: `/cadastros/pessoas/editar/:id`,
    name: "cadastrosPessoasEditar",
    props: true,
    component: () => import("@/views/pages/cadastros/pessoas/register.vue"),
    meta: { title: "Editar Residente", authRequired: false, layout: DefaultLayout }
  },
];

/*const adminParameterRoutes = [
  {
    path: `/admin/parametros/precursores`,

    name: "AdminPrecursors",
    component: () => import("@/views/pages/admin/parameters/precursors/index.vue"),
    meta: { title: "Precursores", authRequired: false, layout: DefaultLayout }
  },
  {
    path: `/admin/parametros/escopos`,
    name: "AdminScopes",
    component: () => import("@/views/pages/admin/parameters/scopes/index.vue"),
    meta: { title: "Escopos", authRequired: false, layout: DefaultLayout }
  },
  {
    path: `/admin/parametros/categorias`,
    name: "AdminCategories",
    component: () => import("@/views/pages/admin/parameters/categories/index.vue"),
    meta: { title: "Escopos", authRequired: false, layout: DefaultLayout }
  },
];*/


export const routes = [
  ...accountRoutes,
  ...dashboardRoutes,
  ...authRoutes,
  ...errorRoutes,
  ...pagesRoutes,
  ...cadastros,
  {
    path: "/logout",
    name: "Logout",
    component: () => import("@/views/account/Logout.vue"),
    meta: { title: "Logout", authRequired: false }
  }
];
